@import "variables";

/*=============================================
=            Helper CSS            =
=============================================*/

@for $i from 0 through 15 {
  .row-#{1 * $i} {
    margin-left: -1px * $i;
    margin-right: -1px * $i;

    & > [class*="col"] {
      padding-left: 1px * $i;
      padding-right: 1px * $i;
    }
  }
}
@for $i from 16 through 80 {
  .row-#{1 * $i} {
    margin-left: -1px * $i;
    margin-right: -1px * $i;

    // Responsive
    @media #{$xl-layout} {
      margin-left: -15px;
      margin-right: -15px;
    }
    @media #{$lg-layout} {
      margin-left: -15px;
      margin-right: -15px;
    }
    @media #{$md-layout} {
      margin-left: -15px;
      margin-right: -15px;
    }
    @media #{$xs-layout} {
      margin-left: -15px;
      margin-right: -15px;
    }

    & > [class*="col"] {
      padding-left: 1px * $i;
      padding-right: 1px * $i;

      // Responsive
      @media #{$xl-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media #{$lg-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media #{$md-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media #{$xs-layout} {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

/* no gutters */
.no-gutters {
  margin-left: 0;
  margin-right: 0;
  & > .col,
  & > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
  }
}


.collections-area .container .collection-wrap .collection-active .swiper-wrap button{
display: none;

  
}

.collections-area .container .collection-wrap .category-buttons .swiper-wrap button{
  display: block;
  border: none;
  background: $theme-color;
  outline: none;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  width: 40px;
  font-size: 25px;
  padding: 0;
  transform: translateY(-50%);
  box-shadow: 0 0 10px rgba(0,0,0,0.3);

  &i{
    line-height: 40px;
    font-size: 20px;
  }
}

.product-slider-navigation .swiper-wrap button{
  display: block;
  border: none;
  background: #4c86c6;
  outline: none;
  cursor: pointer;
  color: white;
}

.product-slider-navigation .swiper-wrap .swiper .swiper-wrapper{
  height: 507px !important;
}


.swiper-pagination .swiper-pagination-clickable .swiper-pagination-bullets .swiper-pagination-horizontal{

  margin-top: 30px;

  & .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
    background: $theme-color;
    opacity: 1;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }

  & .swiper-pagination-bullet-active{
    background: $theme-color;
    opacity: 1;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }
  
}

/*=====  End of Helper CSS  ======*/
